<template>
  <div class="main-menu">
    <div class="row px-2">
      <div class="col-4 col-lg-2 p-2">
        <router-link :to="{name: 'Home'}"><i class="fas fa-home fa-lg"></i><br> หน้าหลัก</router-link>
      </div>

      <div class="col-4 col-lg-2 p-2">
        <router-link :to="{name: 'Lotto'}"><i class="fas fa-th fa-lg"></i><br> หวย</router-link>
      </div>

      <!-- <div class="col-4 col-lg-2 p-2">
        <router-link :to="{name: 'Game'}"><i class="fas fa-gamepad fa-lg"></i><br> เกม</router-link>
      </div> -->

      <div class="col-4 col-lg-2 p-2">
        <router-link :to="{name: 'Tickets'}"><i class="fas fa-list fa-lg"></i><br> รายการโพย</router-link>
      </div>

      <div class="col-4 col-lg-2 p-2">
        <router-link :to="{name: 'Report'}"><i class="fas fa-search-dollar fa-lg"></i><br> การเงิน</router-link>
      </div>

      <div class="col-4 col-lg-2 p-2">
        <router-link :to="{name: 'Results'}"><i class="fas fa-star fa-lg"></i><br> ตรวจผล</router-link>
      </div>

      <!-- <div class="col-4 col-lg-2 p-2">
        <router-link :to="{name: 'Links'}"><i class="fas fa-link fa-lg"></i><br> ลิงค์ดูผล</router-link>
      </div> -->

      <div class="col-4 col-lg-2 p-2">
        <a href="#" @click="logout" class="c-header-nav-link px-3"><i class="fas fa-sign-out-alt fa-lg"></i><br> ออกจากระบบ</a>
      </div>
    </div>
  </div>
</template>
<script>
import Auth from '@/helpers/auth'
import Swal from 'sweetalert2'

export default {
  methods: {
    logout() {
      if(this.$store.state.isProcessTicket) {
        Swal.fire({
          text: 'กรุณาอย่าเปลี่ยนหน้าขณะกำลังส่งโพย',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }else{
        Auth.logout()
      }
    }
  }
}
</script>
<style lang="scss">
.main-menu {
  padding: 0;

  a {
    display: block;
    text-align: center;
    border: 1px solid #383F6B;
    padding: 15px 0px;
    line-height: 1.2;
    border-radius: 5px;
    box-shadow: inset 0 0px 10px rgb(32 82 149 / 20%);
    // opacity: 0.8;
    background-color: #383F6B;
    color: #FFFFFF;
  }

  a:hover {
    text-decoration: none;
    background-color: #BD3217;
    border: 1px solid #BD3217;
    color: #FFFFFF;
  }

  a.active {
    background-color: #BD3217;
    border: 1px solid #BD3217;
  }
}
</style>
